<!-- 耕雲導師圆寂及哀悼文 -->

<template>
    <div class="mourn">
        <div class="titleTop">恩師於兩千年十月十一日凌晨圓寂！！並於十一月五日舉行告別式.</div>

        <table border="1" cellspacing="0">
            <tr>
                <th>{{$t('articleTitle')}}</th>
                <th>{{$t('articleName')}}</th>
            </tr>
            <tr v-for="(item,index) in contentList" :key="index">
                <td class="title" @click="linkToDetail(item)">{{item.news_title}}</td>
                <td>{{item.news_author}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getCateList, getMourningList } from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            contentList: []
        };
    },
    methods: {
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=mourningArticle&id='+item.id);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: {
            //         name: "mourningArticle",
            //         id: item.id,
            //         // title: this.title
            //     }
            // });
        }
    },
    created() {
        getCateList().then(res => {
            let id = res[0][3].id;
            getMourningList(id).then(res => {
                this.contentList = res;
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}

.mourn {
    color: #ffffff;
    width: 752px;
    /* height: 726px; */
    margin: 20px 0px;
    padding: 20px;
    background-color: #000000;

    .titleTop {
        line-height: 40px;
        text-align: left;
        margin-left: 5px;
    }
}

table, td, tr, th {
    border: 1px solid #ACACAC;
}

table {
    width: 100%;
    font-size: 15px;
    text-align: left;

    th {
        line-height: 36px;
        font-weight: 600;
        padding-left: 5px;
    }

    td {
        line-height: 30px;
        text-align: left;
        padding-left: 5px;
    }

    .title:hover {
        cursor: pointer;
        color: #1482d6;
    }
}
</style>